<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <tchtree v-model="searchForm.class_ids"/>
        <el-input placeholder="学生姓名/单位名称" v-model="searchForm.keyword" size="small" class="input-with-select"
          style="width:200px" clearable>
        </el-input>
        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                   @click="() => { page.current_page = 1; getList() }">搜索
        </el-button>
      </div>

      <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">
        <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" size="small" type="primary"
                   @click="tongbuJava">迁移老版本
        </el-button>

        <el-button size="mini" icon="el-icon-download" type="primary" plain style="margin-left: 10px;" @click="exportXLS">导出</el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="实习鉴定列表" name="first"></el-tab-pane>
      <el-tab-pane label="实习鉴定未提交列表" name="second"></el-tab-pane>
    </el-tabs>

    <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
              style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55px" align="center"/>
      <el-table-column label="学生姓名" prop="stu_name" width="100px" align="center"/>
      <el-table-column label="性别" prop="gender" width="60px" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.gender == 1" type="primary">男</el-tag>
          <el-tag size="mini" v-if="scope.row.gender == 2" type="success">女</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="班级" prop="class_name" show-overflow-tooltip width="200px" align="center"/>
      <el-table-column label="专业" prop="yx_name" show-overflow-tooltip width="200px" align="center"/>
      <el-table-column label="实习单位名称" prop="firm_name" show-overflow-tooltip min-width="200px" align="center"/>
      <el-table-column v-if="activeName === 'first'" label="创建时间" prop="create_date" show-overflow-tooltip width="200px" align="center">
        <template slot-scope="scope">{{ scope.row.create_date }}</template>
      </el-table-column>
      <el-table-column v-if="activeName === 'first'" fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-tag class="pointer" @click="handleModify(scope.row)" type="primary" size="mini">查看</el-tag>
          <el-tag class="pointer" @click="handleDel(scope.row)" type="danger" size="mini" style="margin-left: 10px;">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
                   :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
                   :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog class="cus_dialog"  ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false" @close="isRevoke = false"
               custom-class="cus_dialog" width="1000px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">{{ modalTitle }}</span>
      </div>
      <table class="dttable" style="margin-top: 20px;margin-bottom: 20px;">
        <tr>
          <td class="tdheader">学生姓名</td>
          <td style="width:30%">{{ formData.stu_name }}</td>
          <td class="tdheader">性别</td>
          <td style="width:30%">{{ formData.gender === 1 ? '男' : '女' }}</td>
        </tr>
        <tr>
          <td class="tdheader">系部</td>
          <td>{{ formData.yx_name }}</td>
          <td class="tdheader">班级</td>
          <td>{{ formData.class_name }}</td>
        </tr>
        <tr>
          <td class="tdheader">实习单位</td>
          <td colspan="3">{{ formData.firm_name }}</td>
        </tr>
        <tr>
          <td class="tdheader">岗位及内容</td>
          <td colspan="3">{{ formData.job_content }}</td>
        </tr>
        <tr>
          <td class="tdheader">鉴定内容</td>
          <td colspan="3">{{ formData.identify_content }}</td>
        </tr>
        <tr>
          <td class="tdheader">车间部门审核意见</td>
          <td colspan="3">{{ formData.workshop_opinion }}</td>
        </tr>
        <tr>
          <td class="tdheader">车间部门审核签名</td>
          <td colspan="3">
            <el-image v-if="formData.workshop_signature" :src="formData.workshop_signature"
                      :preview-src-list="[formData.workshop_signature]" fit="contain"
                      style="height:50px;margin-left:10px;"></el-image>
          </td>
        </tr>
        <tr>
          <td class="tdheader">人事主管部门审核意见</td>
          <td colspan="3">{{ formData.personnel_opinion }}</td>
        </tr>
        <tr>
          <td class="tdheader">人事主管等级鉴定</td>
          <td colspan="3">{{ formData.personnel_grade }}</td>
        </tr>
        <tr>
          <td class="tdheader">学生系部审核意见</td>
          <td colspan="3">{{ formData.department_opinion }}</td>
        </tr>
        <tr>
          <td class="tdheader">学校审核意见</td>
          <td colspan="3">{{ formData.school_opinion }}</td>
        </tr>
        <tr>
          <td class="tdheader">创建日期</td>
          <td colspan="3">{{ formData.identify_date }}</td>
        </tr>
      </table>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
import moment from "moment";
import {mapState} from "vuex";
import {export_json_to_excel} from "../../../public/js/Export2Excel";

export default {
  components: {tchtree},
  data() {
    return {
      activeName: "first",
      isRevoke: false,
      searchForm: {
        keyword: "",
        status: "",
        class_ids: "",
      },
      classArr: [],
      tableData: [],
      dialogForm: false,
      modalTitle: "添加",
      formData: {},
      sxqx: ["实习", "参军", "升学/升本", "出国", "长病假", "实习结束"],
      qyxz: [], qyxzObj: {},
      salary: [], salaryObj: {},
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      overlap: null,
      spword: "",
      sxqxArr: [], sxqxObj: {},
      applyList: [],
      dialogExportFormVisible: false,
      dialogChangeVisible: false,
      export_format: "word",
      changeList: [],
      dateRangeArr: "",
      deptInfo: {},
      yxList: [],
      isView: false,
      isCheck: false,
      checkBtn: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleClick(tab, event) {
      this.getList()
    },
    getComType() {
      return new Promise(resolve => {
        this.$http
            .post("/api/sys_param_list", {
              param_type: "'ENTER_INFO','SALARY_RANGE','SHIXI_STATUS'",
              used: 0
            })
            .then(res => {
              let qyxz = [];
              let salary = [];
              let sxqxArr = [];
              let qyxzObj = {};
              let salaryObj = {};
              let sxqxObj = {};
              for (let item of res.data) {
                if (item.param_type == "ENTER_INFO") {
                  qyxz.push(item);
                  qyxzObj[item.param_value] = item.param_desc
                }
                if (item.param_type == "SALARY_RANGE") {
                  salary.push(item);
                  salaryObj[item.param_value] = item.param_desc
                }
                if (item.param_type == "SHIXI_STATUS") {
                  sxqxArr.push(item);
                  sxqxObj[item.param_value] = item.param_desc
                }
              }
              this.qyxz = qyxz;
              this.salary = salary;
              this.sxqxArr = sxqxArr;
              this.qyxzObj = qyxzObj;
              this.salaryObj = salaryObj;
              this.sxqxObj = sxqxObj;
              console.log(qyxzObj)
              resolve();
            });
      });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    handleAdd() {
      this.isView = false
      this.dialogForm = true;
      this.modalTitle = "新增台账信息";
      this.resetItem()
      this.formData = {
        school_year: new Date().getFullYear(),
        week: moment().isoWeek(),
        start_date: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        end_date: moment().endOf('isoWeek').format('YYYY-MM-DD'),
        username: this.$store.state.teacher.username,
        dept_name: this.deptInfo.name,
      }
      this.formData.title = `${this.$store.state.teacher.username}的第${this.formData.school_year}年${this.formData.week}周工作台账`
    },
    getList() {
      let data = {};
      data.keyword = this.searchForm.keyword;
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      data.class_ids = this.searchForm.class_ids
      data.is_appraise = this.activeName === 'first' ? 1 : 0
      this.$http.post("/api/wxjd_appraise_list", data).then(res => {
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },
    handleDel(row) {
      this.$confirm('此操作不可恢复，确定删除？', {
        type: 'warning'
      }).then(res => {
        this.$http
            .post("/api/wxjd_appraise_delete", {
              id: row.id
            })
            .then(res => {
              this.$message.success("删除成功");
              this.getList();
            });
      })
    },
    handleModify(row) {
      this.modalTitle = '实习鉴定详情'
      this.formData = {...row}
      this.dialogForm = true
    },
    submitForm(st) {
      this.$refs[st].validate((valid) => {
        if (valid) {
          this.details.tuijian.map(t => {
            t.sTime = t.dateRange[0] || ""
            t.eTime = t.dateRange[1] || ""
          })
          this.details.zhidao.map(t => {
            t.sTime = t.dateRange[0] || ""
            t.eTime = t.dateRange[1] || ""
          })
          this.details.zoufang.map(t => {
            t.sTime = t.dateRange[0] || ""
            t.eTime = t.dateRange[1] || ""
          })
          this.details.other.map(t => {
            t.sTime = t.dateRange[0] || ""
            t.eTime = t.dateRange[1] || ""
          })
          this.formData.detail = JSON.stringify(this.details)
          this.$http.post(this.isCheck ? '/api/teacher_ledger_review' : '/api/teacher_ledger_edit', this.formData).then(res => {
            this.$message.success('提交成功')
            this.getList()
            this.dialogForm = false
            this.resetItem()
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    uploadFiles(e) {
      this.$http
          .post("/api/import_stu_enter_xls", {
            url: e.src
          })
          .then(res => {
            this.$message.success("导入成功");
            if (res.data.errmsg && res.data.errmsg.length > 0) {
              let html = "";
              for (let msg of res.data.msg) {
                html +=
                    "<div style='font-size:12px'>" +
                    msg["姓名"] +
                    ":" +
                    msg.msg +
                    "</div>";
              }
              this.$alert(html, "未导入数据", {
                dangerouslyUseHTMLString: true
              });
            }
            this.getList();
          });
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_stu_appraise", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    getCls(data, id) {
      for (let item of data) {
        if (item.id === Number(id)) {
          return item
        }
        if (item.children && item.children.length) {
          return this.getCls(item.children, id)
        }
      }
    },
    clsExportExcel() {
      this.export_format = 'xls'
      this.exportWordOrPdf()
    },
    exportXLS() {
      let data = {}
      data.keyword = this.searchForm.keyword;
      data.pagesize = 100000;
      data.class_ids = this.searchForm.class_ids
      data.is_appraise = this.activeName === 'first' ? 1 : 0
      this.$http.post('/api/wxjd_appraise_list', data).then(res => {
        let data = res.data.data
        if (!data.length) {
          this.$message.warning('没有数据导出')
          return
        }
        let excelData = []
        data.map((item,index) => {
          let row = [
            ++index,
            item.stu_code,
            item.stu_name,
            item.yx_name,
            item.class_name,
          ]
          if (this.activeName === 'first') {
            row.push(
                item.firm_name,
                item.job_content,
                item.identify_content.replace(/<[^>]+>/g,''),
                item.workshop_opinion,
                item.personnel_opinion,
                item.personnel_grade,
                item.department_opinion,
                item.school_opinion,
                item.create_date)
          }
          excelData.push(row)
        })
        let header = ['序号','学号','学生姓名','系部','班级']
        let title = '实习鉴定未提交名单导出'
        if (this.activeName === 'first') {
          header.push('实习单位','岗位及内容','鉴定内容','车间部门审核意见',
              '人事主管部门审核意见','人事主管等级鉴定','学生系部审核意见','学校审核意见','创建时间')
          title = '实习鉴定已提交名单导出'
        }
        export_json_to_excel(header, excelData,title)
      })
    },
  }
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__body {
  padding: 0px 21px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}

/deep/ .el-tabs__item {
  font-size: 18px;
}

.dialog_right {
  .lineRow {
    .cell:first-of-type {
      width: 120px;
    }

  }
}
</style>
<style>
.cellGroupBarTitle span:nth-of-type(2) {
  color: #2469f3;
}
</style>